import "./styles.css";
import React from "react";
// import { HttpRequest } from "../../component/service/HttpRequest";

function InsaneRatioBar(props) {

    let total = props.total;
    let value = props.value;

    return (
        <div className="insane-ratio-bar">
            {/*<div className="value-bar" style={{ width: value.size + "%"}}></div>*/}

            <div className="value-bar" style={{ width: '100%' }}></div>
            <div className="detail">
                <div className="value">{value.label}</div>
                <div style={{ flexGrow: 1 }}></div>
                <div className="total">{total.label}</div>
            </div>
        </div>
    );
}

export default InsaneRatioBar;
