import "./styles.css";
import React, { Fragment } from "react";

import Spinner from "../../component/spinner";

import TAB_1 from "./tab1";
import TAB_2 from "./tab2";
import TAB_3 from "./tab3";



function App() {

  const [loading, setLoading] = React.useState(false);

  const TABS = [
    { id: "1", title: "สภาพจราจรในพื้นที่ EEC ณ เวลาล่าสุด", element: <TAB_1 /> },
    { id: "2", title: "ช่วงถนนที่ติดซ้ำซาก", element: <TAB_2 /> },
    { id: "3", title: "ทางแยกที่ติดซ้ำซาก", element: <TAB_3 /> },

  ]

  const [TABS_SELECTED, SET_TABS_SELECTED] = React.useState("1");


  return (
    <>
      {loading ? (
        <div
          className="main-content"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Spinner></Spinner>
          <div style={{ color: "#000" }}>กำลังโหลด</div>
        </div>
      ) : (
        <div className="main-content">

          <div className="page-content">

            <div className="content dashboard-page">

              <div className="tab-container" style={{ display: "flex" }}>
                {
                  TABS.map((tab, index) => (
                    <div
                      key={index}
                      className={"tab-button " + (TABS_SELECTED === tab.id ? "active" : "")}
                      onClick={() => SET_TABS_SELECTED(tab.id)}>
                      {tab.title}
                    </div>
                  ))
                }
              </div>

              {
                TABS.map((tab, index) => (
                  (TABS_SELECTED === tab.id) ? (tab.element) : (null)
                ))
              }

            </div>

            <div className="content-mobile"></div>

          </div>
        </div>
      )}
    </>
  );
}

export default App;
