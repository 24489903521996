import "./styles.css";
import React from "react";
import { Chart } from "react-google-charts";

function getRandomNumber() {
    return Math.random() * 100;
}

function GaugeChart({ title }) {
    const [data, setData] = React.useState();
    function getData() {
        return [
            ["Label", "Value"],
            [title, getRandomNumber()],
        ];
    }
    const options = {
        // width: 400,
        // height: 120,
        redFrom: 90,
        redTo: 100,
        yellowFrom: 75,
        yellowTo: 90,
        minorTicks: 5,
    };

    React.useEffect(() => {
        setData(getData());
    }, []);

    return (
        <Chart
            chartType="Gauge"
            //width=''
            height="100%"
            data={data}
            options={options}

        />
    );
}

function CardDashboard({ }) {
    return (
        <GaugeChart title="Speed" />
    )
}

function App() {

    return (
        <CardDashboard />
    );
}

export default App;
