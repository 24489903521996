import "./styles.css";
import React from "react";
import { HttpRequest, HttpRequestForCSV } from "../../service/HttpRequest";
import OpenStreetMap from "../../component/insane-openstreet-map";
import Speed from '../graph/Speed';
import Noimg from '../../assets/images/noimg.png';

function App() {

  const [TRAFFIC_MAP, SET_TRAFFIC_MAP] = React.useState([]);
  const [CIRCLE_MAP, SET_CIRCLE_MAP] = React.useState([]);
  const [ENUM, SET_ENUM] = React.useState(null);
  const [FILTER_TRAFFIC_OPTION, SET_FILTER_TRAFFIC_OPTION] = React.useState([]);

  const [DATA_TRAFFIC, SET_DATA_TRAFFIC] = React.useState(null);
  const [DATA_INTERSECT, SET_DATA_INTERSECT] = React.useState(null);

  const [DISPLAY_MAP_LINK, SET_DISPLAY_MAP_LINK] = React.useState(true);
  const [DATA_MAP_LINK, SET_DATA_MAP_LINK] = React.useState(null);

  const [mapSettingDefault, setMapSettingDefault] = React.useState({
    center: [13.762201991756932, 100.50106010074637],
    zoom: 10,
    raduis_m: 1000
  });

  async function getEnum() {
    HttpRequest("/api/getenum", {}).then((data) => {
      console.log("HttpRequest /api/getenum:", data);
      if (data.result === "OK") {
        let data_enum = data.enum;
        let new_state_option_map = {
          ...mapSettingDefault,
          center: [data_enum.default.center_lat, data_enum.default.canter_lon],
          raduis_m: data_enum.default.raduis_m
        }
        setMapSettingDefault(new_state_option_map);
        for (let i = 0; i < data_enum.filter_traffic.length; i++) {
          data_enum.filter_traffic[i].lists = [];
        }
        SET_FILTER_TRAFFIC_OPTION(data_enum.filter_traffic);
        SET_ENUM(data.enum);
      }
    });
  }

  async function getTraffic() {

    await HttpRequest("/api/getlink",
      {
        "center_lat": 13.28772312454414,
        "center_lon": 101.32467877424523,
        "raduis_m": 1000,
        "index": 0,
        "count": 0
      }
    ).then((data) => {
      console.log("HttpRequest getTraffic :", data);
      if (data.result === "OK") {
        SET_DATA_TRAFFIC(data);
        SET_DATA_MAP_LINK(data.links);
      }
    });
  }

  async function getSensor() {
    await HttpRequest("/api/getsensor",
      {
        "sensor_id": null,

        "province_id": null,
        "index:": null,
        "count": null
      }
    ).then((data) => {
      console.log("HttpRequest getsensor :", data);
    });
  }

  async function getIntersect() {

    await HttpRequest("/api/getintersect",
      {
        "trafficlight": null,
        "index:": null,
        "count": null
      }
    ).then((data) => {
      console.log("HttpRequest getintersect :", data);
      if (data.result === "OK") {
        SET_DATA_INTERSECT(data);
      }
    });
  }

  async function getPlace() {
    await HttpRequest("/api/getplace",
      {
        "search": "",
        "index:": 0,
        "count": 0
      }
    ).then((data) => {
      console.log("HttpRequest getplace :", data);
    });
  }

  React.useEffect(() => {
    const controller = new AbortController();
    getEnum();

    return () => {
      controller.abort();
    };
  }, []);

  React.useEffect(() => {
    const controller = new AbortController();
    SET_TRAFFIC_MAP([]);
    SET_CIRCLE_MAP([]);

    async function init() {
      await getTraffic(); //// Show color line on road
      await getIntersect();
      await getSensor();
      await getPlace();
    }

    if (ENUM) {
      init();
    }

    return () => {
      controller.abort();
    };
  }, [ENUM]);

  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_TRAFFIC && DATA_INTERSECT) {

      let filter_traffic = [...FILTER_TRAFFIC_OPTION];

      for (let i = 0; i < FILTER_TRAFFIC_OPTION.length; i++) {
        const key = FILTER_TRAFFIC_OPTION[i].id;

        if (DATA_TRAFFIC[key]) {
          filter_traffic[i].lists = DATA_TRAFFIC[key];
        }

        if (DATA_INTERSECT[key]) {
          filter_traffic[i].lists = DATA_INTERSECT[key];
        }

        let count_max = 0;

        for (let j = 0; j < filter_traffic[i].lists.length; j++) {
          let value = filter_traffic[i].lists[j].value;
          if (value > count_max) {
            count_max = value;
          }
          filter_traffic[i].lists[j].type = key;
        }

        for (let j = 0; j < filter_traffic[i].lists.length; j++) {
          filter_traffic[i].lists[j].total = count_max;
          filter_traffic[i].lists[j].percent_value = ((filter_traffic[i].lists[j].value / count_max) * 100).toFixed(1);
        }
      }

      SET_FILTER_TRAFFIC_OPTION(filter_traffic);
    }

    return () => {
      controller.abort();
    };
  }, [DATA_TRAFFIC, DATA_INTERSECT]);

  React.useEffect(() => {
    const controller = new AbortController();
    // console.log("DATA_TRAFFIC",DATA_TRAFFIC);
    // console.log("DATA_MAP_LINK",DATA_MAP_LINK);
    if (DATA_TRAFFIC) {
      if (DISPLAY_MAP_LINK && DATA_MAP_LINK) {
        let polyline = [...DATA_MAP_LINK];
        for (let i = 0; i < polyline.length; i++) {
          polyline[i].color = "#999";
          let status = ENUM.condition_traffic.filter((legend) => polyline[i].status === legend.status);
          polyline[i].type = "traffic";
          if (status.length > 0) {
            if (polyline[i].focus) {
              // console.log(polyline[i]);
              polyline[i].color = "#46e2fa";
            } else {
              polyline[i].color = status[0].color;
            }
          }
        }
        SET_TRAFFIC_MAP(polyline);
        setTimeout(() => {
        }, 3000);
      } else {
        setTimeout(() => {
          SET_TRAFFIC_MAP([]);
        }, 1000);
      }
    }
    return () => {
      controller.abort();
    };
  }, [DISPLAY_MAP_LINK, DATA_MAP_LINK]);

  React.useEffect(() => {
    const controller = new AbortController();
    return () => {
      controller.abort();
    };
  });

  //  Speed morter
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="content">
            <div className="flex-container">
              <span style={{ margin: '1px 1px 1px 1px' }}>
                <label style={{ backgroundColor: 'blue', width: '100px', color: 'white', height: '30px', textAlign: 'center' }}>พื้นที่</label>
                <span >
                  <select className='' style={{ width: '100px', height: '20px', textAlign: 'left' }}>
                    <option selected>EEC</option>

                    <option >ระยอง</option>
                    <option >ชลบุรี</option>
                    <option >ฉะเชิงเทรา</option>
                  </select>
                </span>
              </span>
              <span style={{ margin: '1px 1px 1px 1px' }}>
                <label style={{ backgroundColor: 'blue', width: '100px', color: 'white', height: '30px', textAlign: 'center' }}>เวลา</label>
                <span >
                  <select style={{ width: '100px', height: '20px' }}>
                    <option>เวลาล่าสุด</option>
                    <option>06.00 - 9.00 น.</option>
                    <option>11.00 - 13.00 น.</option>
                    <option>16.00 - 19.00 น.</option>
                  </select>
                </span>
              </span>
              <div style={{ textAlign: 'right', marginRight: '20px', width: '70%' }}> <div><b>5 มิถุนายน 2567</b></div>
              </div>
            </div>

            <OpenStreetMap
              traffic={TRAFFIC_MAP}
              circle={CIRCLE_MAP}
              center={mapSettingDefault.center}
              zoom={mapSettingDefault.zoom}
            // style={{ position: 'relative' }}
            />
            <div style={{ position: 'fixed', bottom: '20px', left: '20px', zIndex: '9999', backgroundColor: 'white', width: '7%', textAlign: 'center', borderRadius: '4px', margin: '5px', padding: '5px' }}>
              <div> <b>สภาพจราจร
                <div style={{ backgroundColor: 'green', borderRadius: '4px', margin: '7px' }}>คล่องตัว</div>
                <div style={{ backgroundColor: 'yellow', borderRadius: '4px', margin: '7px' }}>หนาแน่น</div>
                <div style={{ backgroundColor: 'crimson', borderRadius: '4px', margin: '7px', color: 'white' }}>ติดขัด</div>
                <div style={{ backgroundColor: 'red', borderRadius: '4px', margin: '7px', color: 'white' }}>ติดขัดมาก</div>
                <div style={{ backgroundColor: 'darkgray', borderRadius: '4px', margin: '7px', color: 'white' }}>ไม่มีข้อมูล</div>
              </b>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="content-view" style={{ height: '55%' }}>
              <div className="flex-container">
                <span className="flex-a">
                  <div style={{ backgroundColor: '#50575e', height: '6vh', margin: '1px 10px 5px 1px', padding: '16px 1px 1px 1px', textAlign: 'center', color: 'white' }}>ความเร็วเฉลี่ย</div>
                  <div style={{
                    backgroundColor: '#facfd2', height: '4vh', margin: '1px 10px 5px 1px', padding: '5px 5px 1px 1px',
                    textAlign: 'left'
                  }}>
                    <div style={{ margin: '0px 10px 10px 5px' }}><b>เส้นทางที่เลือก</b></div>
                  </div>
                  <div className="card-summary" style={{
                    border: '',
                    backgroundColor: 'white',
                    marginRight: '10px',
                    height: '29vh'
                  }}>
                    <div className="chart" style={{ justifyContent: 'center', alignItems: 'center', height: '29vh', marginLeft: '20vh', }}>
                      <Speed />
                    </div>
                  </div>
                </span>
                <span className="flex-b" >
                  <div style={{ backgroundColor: '#50575e', height: '13vh', marginBottom: '5px' }}>
                    <h1 className="flex-b-h1"><b>50</b></h1>
                    <div className="flex-b-p">จำนวนอุบัติเหตุยานยนต์ (ครั้ง) ปี พ.ศ.2567</div>
                  </div>
                  <div style={{ backgroundColor: '#50575e', height: '13vh', marginBottom: '5px' }}>
                    <h1 className="flex-b-h1"><b>20</b></h1>
                    <div className="flex-b-p">จำนวนรถบรรทุกที่วิ่งบนเขตห้ามเข้าและจอด (คัน/วัน)</div>
                  </div>
                  <div style={{ backgroundColor: '#50575e', height: '13vh', marginBottom: '5px' }}>
                    <h1 className="flex-b-h1"><b>10</b></h1>
                    <div className="flex-b-p">จำนวนรถบรรทุกที่วิ่งเกินความเร็วที่กฏหมายกำหนด (คัน/วัน)</div>
                  </div>
                </span>
              </div>
            </div>
            <div className="content-view" style={{ height: '45%' }}>
              <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100%', width: '100%' }}>
                <img src={Noimg} alt="No Image" style={{ height: '100%', width: '100%', margin: '5px 0px 0px 0px' }} />
              </div>
            </div>
          </div>
        </div>
      </div >

    </>
  );
}

export default App;
