import React from "react";
import { Chart } from "react-google-charts";

const data = [
    ['Road', 'ความเร็ว'],
    ["Road 1", 40],
    ["Road 2", 38],
    ["Road 3", 35],
    ["Road 4", 27],
    ["Road 5", 22],
    ["Road 6", 18],
    ["Road 7", 15],
    ["Road 8", 12],
    ["Road 9", 9],
    ["Road 10", 5],
];

const options = {
    title: "",
    chartArea: { width: "50%" },
    hAxis: {
        title: "ความเร็วเฉลี่ย (กิโลเมตร / ชั่วโมง)",
        minValue: 0,
    },
    vAxis: {
        title: "Roads",
    },
};

function App() {
    return (
        <Chart
            chartType="BarChart"
            width="100%"
            height="100%"
            data={data}
            options={options}
        />
    );
}

export default App;
