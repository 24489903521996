import "./styles.css";
import React, { Fragment } from "react";

function InsaneLoadingProgress(props) {

  const [SHOW, SET_SHOW] = React.useState(false);
  const [PercentTageProgress, SET_PercentTageProgress] = React.useState(0);

  React.useEffect(() => {
    if (props.show === true) {
      SET_SHOW(true);
    } else {
      SET_SHOW(false);
    }
  }, [props.show]);

  React.useEffect(() => {
    if (props.value && props.total) {
      let percent = (props.value / props.total) * 100;
      SET_PercentTageProgress(percent);
    }
  }, [props.value]);

  React.useEffect(() => {
    if (PercentTageProgress === 100) {
      setTimeout(() => {
        SET_SHOW(false);
      }, 5000);
    }
  }, [PercentTageProgress]);

  return (
    <div className={"insane-loading-progress " + (SHOW ? "active " : null) + props.className}>
      <div className="title">
        {
          PercentTageProgress === 100 ? ("โหลดสภาพจราจรแล้ว") : (props.title)
        }
      </div>
      <div className="title">
        {props.value + "/" + props.total} ({PercentTageProgress.toFixed(0) + "%"})
      </div>
      <div className="progress-bar">
        <div className="inner-bar" style={{ width: (PercentTageProgress + "%") }}></div>
      </div>
    </div>
  );
}

export default InsaneLoadingProgress;
