import './styles.css';
import React from 'react';
import gifLoading from "../../assets/images/ss.svg";

function Spinner(props) {
    return <img
        className="animate-spin"
        src={gifLoading}
        style={{ 
            width: props.size ? props.size : null
         }}
    />
}

export default Spinner;