import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import NavigationBar from '../component/navbar';

const Layout = () => {
  return <Fragment>
    <NavigationBar/>
    <Outlet />
  </Fragment>;
}

export default Layout;