import "./styles.css";
import React from "react";
import { HttpRequest, HttpRequestForCSV } from "../../service/HttpRequest";
import OpenStreetMap from "../../component/insane-openstreet-map";
import BarChart from "../graph/BarChart";
import BarChartGoogle from "../graph/BarChartGoogle";



function App() {

  const [TRAFFIC_MAP, SET_TRAFFIC_MAP] = React.useState([]);
  const [CIRCLE_MAP, SET_CIRCLE_MAP] = React.useState([]);
  const [ENUM, SET_ENUM] = React.useState(null);
  const [FILTER_TRAFFIC_OPTION, SET_FILTER_TRAFFIC_OPTION] = React.useState([]);

  const [DATA_TRAFFIC, SET_DATA_TRAFFIC] = React.useState(null);
  const [DATA_INTERSECT, SET_DATA_INTERSECT] = React.useState(null);

  const [DISPLAY_MAP_LINK, SET_DISPLAY_MAP_LINK] = React.useState(true);
  const [DATA_MAP_LINK, SET_DATA_MAP_LINK] = React.useState(null);

  const [mapSettingDefault, setMapSettingDefault] = React.useState({
    center: [13.762201991756932, 100.50106010074637],
    zoom: 10,
    raduis_m: 1000
  });

  async function getEnum() {
    HttpRequest("/api/getenum", {}).then((data) => {
      console.log("HttpRequest /api/getenum:", data);
      if (data.result === "OK") {
        let data_enum = data.enum;
        let new_state_option_map = {
          ...mapSettingDefault,
          center: [data_enum.default.center_lat, data_enum.default.canter_lon],
          raduis_m: data_enum.default.raduis_m
        }
        setMapSettingDefault(new_state_option_map);
        for (let i = 0; i < data_enum.filter_traffic.length; i++) {
          data_enum.filter_traffic[i].lists = [];
        }
        SET_FILTER_TRAFFIC_OPTION(data_enum.filter_traffic);
        SET_ENUM(data.enum);
      }
    });
  }

  async function getTraffic() {

    await HttpRequest("/api/getlink",
      {
        "center_lat": 13.28772312454414,
        "center_lon": 101.32467877424523,
        "raduis_m": 1000,


        "index": 0,
        "count": 0
      }
    ).then((data) => {
      console.log("HttpRequest getTraffic :", data);
      if (data.result === "OK") {
        SET_DATA_TRAFFIC(data);
        SET_DATA_MAP_LINK(data.links);
      }
    });
  }

  async function getSensor() {
    await HttpRequest("/api/getsensor",
      {
        "sensor_id": null,

        "province_id": null,
        "index:": null,
        "count": null
      }
    ).then((data) => {
      console.log("HttpRequest getsensor :", data);
    });
  }

  async function getIntersect() {

    await HttpRequest("/api/getintersect",
      {
        "trafficlight": null,
        "index:": null,
        "count": null
      }
    ).then((data) => {
      console.log("HttpRequest getintersect :", data);
      if (data.result === "OK") {
        SET_DATA_INTERSECT(data);
      }
    });
  }

  async function getPlace() {
    await HttpRequest("/api/getplace",
      {
        "search": "",
        "index:": 0,
        "count": 0
      }
    ).then((data) => {
      console.log("HttpRequest getplace :", data);
    });
  }

  React.useEffect(() => {
    const controller = new AbortController();
    getEnum();

    return () => {
      controller.abort();
    };
  }, []);


  React.useEffect(() => {
    const controller = new AbortController();
    SET_TRAFFIC_MAP([]);
    SET_CIRCLE_MAP([]);

    async function init() {
      await getTraffic(); //// Show color line on road
      await getIntersect();
      await getSensor();
      await getPlace();
    }

    if (ENUM) {
      init();
    }

    return () => {
      controller.abort();
    };
  }, [ENUM]);

  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_TRAFFIC && DATA_INTERSECT) {

      let filter_traffic = [...FILTER_TRAFFIC_OPTION];

      for (let i = 0; i < FILTER_TRAFFIC_OPTION.length; i++) {
        const key = FILTER_TRAFFIC_OPTION[i].id;

        if (DATA_TRAFFIC[key]) {
          filter_traffic[i].lists = DATA_TRAFFIC[key];
        }

        if (DATA_INTERSECT[key]) {
          filter_traffic[i].lists = DATA_INTERSECT[key];
        }

        let count_max = 0;

        for (let j = 0; j < filter_traffic[i].lists.length; j++) {
          let value = filter_traffic[i].lists[j].value;
          if (value > count_max) {
            count_max = value;
          }
          filter_traffic[i].lists[j].type = key;
        }

        for (let j = 0; j < filter_traffic[i].lists.length; j++) {
          filter_traffic[i].lists[j].total = count_max;
          filter_traffic[i].lists[j].percent_value = ((filter_traffic[i].lists[j].value / count_max) * 100).toFixed(1);
        }
      }

      SET_FILTER_TRAFFIC_OPTION(filter_traffic);
    }

    return () => {
      controller.abort();
    };
  }, [DATA_TRAFFIC, DATA_INTERSECT]);



  React.useEffect(() => {
    const controller = new AbortController();
    // console.log("DATA_TRAFFIC",DATA_TRAFFIC);
    // console.log("DATA_MAP_LINK",DATA_MAP_LINK);
    if (DATA_TRAFFIC) {
      if (DISPLAY_MAP_LINK && DATA_MAP_LINK) {
        let polyline = [...DATA_MAP_LINK];
        for (let i = 0; i < polyline.length; i++) {
          polyline[i].color = "#999";
          let status = ENUM.condition_traffic.filter((legend) => polyline[i].status === legend.status);
          polyline[i].type = "traffic";
          if (status.length > 0) {
            if (polyline[i].focus) {
              // console.log(polyline[i]);
              polyline[i].color = "#46e2fa";
            } else {
              polyline[i].color = status[0].color;
            }
          }
        }
        SET_TRAFFIC_MAP(polyline);
        setTimeout(() => {
        }, 3000);
      } else {
        setTimeout(() => {
          SET_TRAFFIC_MAP([]);
        }, 1000);
      }
    }
    return () => {
      controller.abort();
    };
  }, [DISPLAY_MAP_LINK, DATA_MAP_LINK]);


  React.useEffect(() => {
    const controller = new AbortController();
    return () => {
      controller.abort();
    };
  });



  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="content">
            <div className="flex-container">
              <span style={{ margin: '1px 1px 1px 1px' }}>
                <label style={{ backgroundColor: 'blue', width: '100px', color: 'white', height: '30px', textAlign: 'center' }}>พื้นที่</label>
                <span >
                  <select style={{ width: '100px', height: '20px', textAlign: 'left' }}>
                    <option>EEC</option>
                    <option>ระยอง</option>
                    <option>ชลบุรี</option>
                    <option>ฉะเชิงเทรา</option>
                  </select>
                </span>
              </span>
              <span style={{ margin: '1px 1px 1px 1px' }}>
                <label style={{ backgroundColor: 'blue', width: '100px', color: 'white', height: '30px', textAlign: 'center' }}>เวลา</label>
                <span >
                  <select style={{ width: '100px', height: '20px' }}>
                    <option>เวลาล่าสุด</option>
                    <option>06.00 - 9.00 น.</option>
                    <option>11.00 - 13.00 น.</option>
                    <option>16.00 - 19.00 น.</option>
                  </select>
                </span>
              </span>
              <span style={{ margin: '1px 1px 1px 1px' }}>
                <label style={{ backgroundColor: 'blue', width: '100px', color: 'white', height: '30px', textAlign: 'center' }}>ช่วงเวลา</label>
                <span >
                  <select style={{ width: '100px', height: '20px', textAlign: '' }}>
                    <option>1 เดือนล่าสุด</option>
                    <option>3 เดือนล่าสุด</option>
                    <option>6 เดือนล่าสุด</option>
                    <option>12 เดือนล่าสุด</option>
                  </select>
                </span>
              </span>
            </div>

            <OpenStreetMap
              traffic={TRAFFIC_MAP}
              circle={CIRCLE_MAP}
              center={mapSettingDefault.center}
              zoom={mapSettingDefault.zoom}
            />
            <div style={{ position: 'fixed', bottom: '20px', left: '20px', zIndex: '9999', backgroundColor: 'white', width: '7%', textAlign: 'center', borderRadius: '4px', margin: '5px', padding: '5px' }}>
              <div> <b>สภาพจราจร
                <div style={{ backgroundColor: 'green', borderRadius: '4px', margin: '7px' }}>คล่องตัว</div>
                <div style={{ backgroundColor: 'yellow', borderRadius: '4px', margin: '7px' }}>หนาแน่น</div>
                <div style={{ backgroundColor: 'crimson', borderRadius: '4px', margin: '7px', color: 'white' }}>ติดขัด</div>
                <div style={{ backgroundColor: 'red', borderRadius: '4px', margin: '7px', color: 'white' }}>ติดขัดมาก</div>
                <div style={{ backgroundColor: 'darkgray', borderRadius: '4px', margin: '7px', color: 'white' }}>ไม่มีข้อมูล</div>
              </b>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="content-view" style={{ height: '100%' }}>
              <div className="flex-a" style={{ textAlign: 'left', width: '100%' }}>
                <div style={{ backgroundColor: '#a9a9a9', height: '30px', margin: '5px 5px 5px 0px', padding: '5px 5px 5px 5px', width: '100%' }}><b>ช่วงถนนที่การจราจรติดขัดซ้ำซาก 10 อันดับแรก</b></div>
              </div>
              <div className="flex-a" style={{ width: '100%', height: '100%' }}>
                <BarChartGoogle />
              </div>
            </div>
          </div>

        </div>
      </div >

    </>
  );
}

export default App;
