import "./styles.css";
import React from "react";

import { HttpRequest } from "../../service/HttpRequest";
import { setCookie } from "../../service/CookieManagement";
// import { useAuth } from "../../component/service/Authentication";

import Spinner from "../../component/spinner";

// import logo from '../../assets/logo.png';

import {
  useGlobalContext,
  setAuthentication,
  // setConfigServer
} from "../../service/GlobalContext";

import { useNavigate } from "react-router-dom";

function LoginPage() {
  // const auth = useAuth();

  const [globalState, setGlobalState] = useGlobalContext();
  const navigate = useNavigate();

  const [formControl, setFormControl] = React.useState({
    username: "",
    password: "",
  });

  const [stateLogin, setStateLogin] = React.useState("loading"); // ready, false, loading, success

  const handleSubmit = (event) => {
    event.preventDefault();

    setStateLogin("loading");

    // let authenData = {
    //   token: "DUMMY-TOKEN",
    //   user: "DUMMY-USER"
    // }

    // setStateLogin("success");
    // setTimeout(auth.login(authenData), 3000);

    let dataReq = {
      username: formControl.username,
      password: formControl.password
    }

    HttpRequest("/api/login", dataReq).then(data => {
      // console.log(data);

      if (data.result === "OK") {

        let authenData = {
          token: data.session.sessiontoken,
          user: data.user,
          permission: data.permission
        }

        setCookie(globalState.config_app.cookie_key_session_token, authenData.token);

        setStateLogin("success");

        setTimeout(async () => {

          navigate("/");
          setAuthentication(setGlobalState, authenData);

        }, 2000);

      } else {
        setStateLogin("false");
      }

    });
  };

  React.useEffect(() => {
    const controller = new AbortController();

    HttpRequest("/api/checksession", {}).then((data) => {
      // console.log("HttpRequest :", data);
      if (data.result === "OK") {
        if (data.session.logout === false) {

          let authenData = {
            token: data.session.sessiontoken,
            user: data.user,
            permission: data.permission
          }

          setCookie(globalState.config_app.cookie_key_session_token, authenData.token);

          setStateLogin("success");

          setTimeout(async () => {

            navigate("/");
            setAuthentication(setGlobalState, authenData);

          }, 2000);

        } else {
          setStateLogin("ready");
        }
      } else {
        setStateLogin("ready");
      }
    });

    return () => {
      controller.abort();
    };
  }, [globalState.auth]);

  return (
    <div className="main-content bg-login">
      <form className="form-login-content" onSubmit={handleSubmit}>

        <p className="form-subtoppic">EEC - Dashboard</p>

        {stateLogin === "ready" ? (
          <>
            <div className="form-row">
              <label className="form-label">Username</label>
              <input
                type="text"
                placeholder="Username"
                className="form-control"
                value={formControl.username}
                onChange={(event) =>
                  setFormControl({
                    ...formControl,
                    username: event.target.value,
                  })
                }
              />
            </div>

            <div className="form-row">
              <label className="form-label">Password</label>
              <input
                type="password"
                placeholder="Password"
                className="form-control"
                value={formControl.password}
                onChange={(event) =>
                  setFormControl({
                    ...formControl,
                    password: event.target.value,
                  })
                }
              />
            </div>

            <div className="form-row">
              <button className="bt-login form-control" type="submit">
                Login
              </button>
            </div>

            <div className="form-row">
              <span className="q">Don't have an Account?</span>
              <a className="h">Contact Administrator</a>
            </div>
          </>
        ) : null}

        {stateLogin === "loading" ? (
          <>
            <div className="form-row">
              <Spinner></Spinner>
              <label style={{ marginLeft: 15 }} className="form-label">
                Authenticating..
              </label>
            </div>
          </>
        ) : null}

        {stateLogin === "false" ? (
          <>
            <div className="form-row">
              <label className="form-label">Authentication Fail</label>
            </div>

            <div className="form-row">
              <span className="q">Please login again.</span>
              <a className="h" onClick={() => setStateLogin("ready")}>
                OK
              </a>
            </div>
          </>
        ) : null}

        {stateLogin === "success" ? (
          <>
            <div className="form-row">
              <label className="form-label">Authen Success</label>
            </div>

            <div className="form-row">
              <Spinner></Spinner>
              <span style={{ marginLeft: 15 }} className="q">
                Redirecting..
              </span>
            </div>
          </>
        ) : null}
      </form>
    </div>
  );
}

export default LoginPage;

